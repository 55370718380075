import {
  Paragraph,
  ContentSection,
  SectionHeading,
} from '@vwfs-bronson/bronson-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Analytics, AnalyticsEvent } from '../../../services/analytics'
import { getLandingPage } from '../../../services/redux/features/page'

const brandName = process.env.REACT_APP_BRAND

export default function ErrorPage() {
  React.useEffect(() => {
    Analytics.track(new AnalyticsEvent.ErrorPageView())
  }, [])

  const { t } = useTranslation()

  const landingPage = useSelector(getLandingPage)

  return (
    <React.Fragment>
      <ContentSection pageWrapSize="small">
        <SectionHeading center level={3}>
          {t('error:heading')}
        </SectionHeading>
      </ContentSection>
      <ContentSection pageWrapSize="small">
        <Paragraph>{t('error:p-1')}</Paragraph>
        <Paragraph>{t('error:p-2')}</Paragraph>
        <Paragraph>{t('error:p-3')}</Paragraph>
        <Paragraph>{t('error:p-4')}</Paragraph>
        {brandName === 'vw' && <Paragraph>{t('error:p-5')}</Paragraph>}
      </ContentSection>
      <ContentSection pageWrapSize="small">
        <div className="u-text-center">
          <a
            className="c-btn"
            href={`${window.location.origin}/${landingPage}`}
          >
            {t('error:backToMain')}
          </a>
        </div>
      </ContentSection>
    </React.Fragment>
  )
}
