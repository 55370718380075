import {
  BackToTop,
  Button,
  ContextBar,
  Footer,
  Header,
  Logo,
  Spinner,
  Wrapper,
} from '@vwfs-bronson/bronson-react'
import React, { useState, useLayoutEffect, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from 'react-router-dom'
import { FooterLinks } from './components/FooterLinks'
import JourneyCompleted from './components/ActiveSaveAndContinue/journeyCompleted'
import LinkExpiry from './components/ActiveSaveAndContinue/linkExpiry'
import { HeroTeaserOpt } from './components/HeroTeaserOpt/HeroTeaserOpt'
import { TanAuthentification } from './components/TanAuthentification'
import FormScreen from './screens/Form'
import ErrorPage from './screens/Pages/error/error'
import EsignRefused from './screens/Pages/esign/esign-refused/esign-refused'
import EsignSuccessful from './screens/Pages/esign/esign-successful/esign-successful'
import pages from './screens/Pages/index.json'
import WithTransaction from './services/api/transaction/withTransaction'
import { isWorkshop } from './services/common/util'
import { qs } from './services/routing'

const brandName = process.env.REACT_APP_BRAND

const Lazy = props => {
  const { page, ...componentProps } = props
  const [component, setComponent] = useState(null)

  useLayoutEffect(() => {
    if (!page) return

    setComponent(() =>
      lazy(() =>
        import(`./screens/Pages/${page}.js`).then(module => ({
          default: module.default,
        }))
      )
    )
  }, [page])

  if (!page) return null
  if (!component) return null
  return (
    <Suspense fallback={<Spinner className="u-m-xlarge" center></Spinner>}>
      {React.createElement(component, componentProps)}
    </Suspense>
  )
}

export function App({ keycloak, keycloakLogout }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pageName } = useParams()

  function getPageFile() {
    if (!pages.length) {
      return ''
    }
    const page = pages.find(p => p.id === pageName)
    if (!page) {
      navigate('/error')
      // eslint-disable-next-line consistent-return
      return 'error/error'
    }

    // eslint-disable-next-line consistent-return
    return page.file || ''
  }

  const id = qs(document.location.search, 'id')

  return (
    <div className="o-root">
      <React.Fragment>
        <Wrapper pageWrapper>
          <Header
            staticHeader
            logo={<Logo ariaLabel="Logo" title="" href="/" />}
          >
            <ContextBar>
              <ContextBar.SiteNav />
              <ContextBar.SiteNav>
                {keycloak.authenticated && (
                  <Button
                    icon="brand-icon"
                    onClick={() => keycloakLogout()}
                    className="u-mr"
                  >
                    {t('page:logout')}
                  </Button>
                )}
              </ContextBar.SiteNav>
            </ContextBar>
          </Header>

          <React.Suspense
            fallback={<Spinner className="u-m-xlarge" center></Spinner>}
          >
            <Routes>
              <Route
                path="/continue"
                element={<TanAuthentification visible />}
              />
              <Route
                path="/page/:pageName"
                element={
                  <WithTransaction>
                    <Wrapper main>
                      <Lazy page={getPageFile()} />
                    </Wrapper>
                  </WithTransaction>
                }
              />
              <Route
                path="/form/:step?"
                element={
                  <WithTransaction>
                    <main className="o-main">
                      <FormScreen config={{}} />
                    </main>
                  </WithTransaction>
                }
              />
              <Route
                path="/error"
                element={
                  <Wrapper>
                    <ErrorPage />
                  </Wrapper>
                }
              />
              <Route
                path="/esign-start"
                element={
                  <Wrapper main>
                    <Lazy page="esign/esign-landing" />
                  </Wrapper>
                }
              />
              <Route
                path="/esign-ready"
                element={
                  <Wrapper main>
                    <Lazy page="esign/esign-landing" />
                  </Wrapper>
                }
              />
              <Route
                path="/esign-refused"
                element={
                  <Wrapper main>
                    <EsignRefused />
                  </Wrapper>
                }
              />
              <Route
                path="/esign-successful"
                element={
                  <WithTransaction transaction={id}>
                    <Wrapper main>
                      <EsignSuccessful transaction={id} />
                    </Wrapper>
                  </WithTransaction>
                }
              />
              <Route
                path="/journey-completed"
                element={
                  <Wrapper main>
                    <JourneyCompleted />
                  </Wrapper>
                }
              />
              <Route
                path="/link-expired"
                element={
                  <Wrapper main>
                    <LinkExpiry />
                  </Wrapper>
                }
              />
              <Route
                path="/dealer"
                element={
                  <WithTransaction transaction={id}>
                    <HeroTeaserOpt
                      inverted={brandName === 'skoda' || brandName === 'audi'}
                      shallow={brandName === 'vw'}
                      focusPart={brandName === 'vw' ? 'top-left' : ''}
                      title={t('landingPageDealer:hero.title')}
                      sub={t('landingPageDealer:hero.subtitle')}
                      imageUrl={`${
                        process.env.PUBLIC_URL
                      }/assets/images/hero_${brandName}_w960${
                        isWorkshop && brandName === 'audi' ? '_workshop' : ''
                      }.jpg`}
                      imageSrcset={imgType => {
                        let ext = null
                        if (imgType === 'image/png') {
                          ext = 'png'
                        } else if (imgType === 'image/jpeg') {
                          ext = 'jpg'
                        } else if (imgType === 'image/webp') {
                          ext = 'webp'
                        } else {
                          return ''
                        }
                        const srcSet = `${
                          process.env.PUBLIC_URL
                        }/assets/images/hero_${brandName}_w480.${ext} 480w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w720${
                          isWorkshop && brandName === 'audi' ? '_workshop' : ''
                        }.${ext} 720w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w960${
                          isWorkshop && brandName === 'audi' ? '_workshop' : ''
                        }.${ext} 960w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w1280${
                          isWorkshop ? '_workshop' : ''
                        }.${ext} 1280w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w1920${
                          isWorkshop && brandName === 'audi' ? '_workshop' : ''
                        }.${ext} 1920w`
                        return srcSet
                      }}
                      imgSizes="100vw"
                    />
                    <Wrapper>
                      <Lazy page="landing-page-dealer" />
                    </Wrapper>
                  </WithTransaction>
                }
              />
              <Route
                path="/"
                element={
                  <WithTransaction transaction={id}>
                    <HeroTeaserOpt
                      inverted={brandName === 'skoda' || brandName === 'audi'}
                      shallow={brandName === 'vw'}
                      focusPart={brandName === 'vw' ? 'top-left' : ''}
                      title={t('landingPage:hero.title')}
                      sub={t('landingPage:hero.subtitle')}
                      imageUrl={`${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w960.jpg`}
                      imageSrcset={imgType => {
                        let ext = null
                        if (imgType === 'image/png') {
                          ext = 'png'
                        } else if (imgType === 'image/jpeg') {
                          ext = 'jpg'
                        } else if (imgType === 'image/webp') {
                          ext = 'webp'
                        } else {
                          return ''
                        }
                        const srcSet = `${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w480.${ext} 480w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w720.${ext} 720w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w960.${ext} 960w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w1280.${ext} 1280w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w1920.${ext} 1920w`
                        return srcSet
                      }}
                      imgSizes="100vw"
                    />
                    <Wrapper>
                      <Lazy page="landing-page" pathName="landingPage" />
                    </Wrapper>
                  </WithTransaction>
                }
              />
              <Route
                path="/vehicleMismatch"
                element={
                  <WithTransaction transaction={id}>
                    <HeroTeaserOpt
                      inverted={brandName === 'skoda' || brandName === 'audi'}
                      shallow={brandName === 'vw'}
                      focusPart={brandName === 'vw' ? 'top-left' : ''}
                      title={t('landingPage:hero.title')}
                      sub={t('landingPage:hero.subtitle')}
                      imageUrl={`${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w960.jpg`}
                      imageSrcset={imgType => {
                        let ext = null
                        if (imgType === 'image/png') {
                          ext = 'png'
                        } else if (imgType === 'image/jpeg') {
                          ext = 'jpg'
                        } else if (imgType === 'image/webp') {
                          ext = 'webp'
                        } else {
                          return ''
                        }
                        const srcSet = `${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w480.${ext} 480w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w720.${ext} 720w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w960.${ext} 960w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w1280.${ext} 1280w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w1920.${ext} 1920w`
                        return srcSet
                      }}
                      imgSizes="100vw"
                    />
                    <Wrapper>
                      <Lazy page="landing-page" pathName="vehicleMismatch" />
                    </Wrapper>
                  </WithTransaction>
                }
              />
              <Route path="*" element={<Navigate to="/error" replace />} />
            </Routes>
          </React.Suspense>

          <BackToTop>{t('page:backToTop')}</BackToTop>
          <Footer>
            <Footer.ModuleMetaNav copyrightText={t('page:copyright')}>
              <FooterLinks />
            </Footer.ModuleMetaNav>
          </Footer>
        </Wrapper>
      </React.Fragment>
    </div>
  )
}
