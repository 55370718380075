export const SET_MAINTENANCE_DATA = 'form//SET_MAINTENANCE_DATA'
export const SET_MAINTENANCE_PLUS_DATA = 'form//SET_MAINTENANCE_PLUS_DATA'

export function setMaintenanceData(data) {
  return {
    type: SET_MAINTENANCE_DATA,
    payload: data,
  }
}

export function getMaintenanceData(store) {
  return store.product.maintenance
}

export function setMaintenancePlusData(data) {
  return {
    type: SET_MAINTENANCE_PLUS_DATA,
    payload: data,
  }
}

export function getMaintenancePlusData(store) {
  return store.product.maintenancePlus
}

const initialState = {
  maintenance: {},
  maintenancePlus: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAINTENANCE_DATA:
      return {
        ...state,
        maintenance: action.payload,
      }

    case SET_MAINTENANCE_PLUS_DATA:
      return {
        ...state,
        maintenancePlus: action.payload,
      }

    default:
      return state
  }
}
