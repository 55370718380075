import React, { useRef, useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Input,
  ErrorMessage,
  Fieldset,
  Paragraph,
  Button,
  Form,
  Layout,
} from '@vwfs-bronson/bronson-react'
import { useNavigate } from 'react-router-dom'
import { BrFormField } from '../FormField'
import { TanAuthentificationValidationSchema } from '../../services/validation'
import { continueJourney } from '../../services/api/journey/continueJourney'
import { qs } from '../../services/routing'
import { generateTan } from '../../services/api/tan'
import { Analytics, AnalyticsEvent } from '../../services/analytics'

export function TanAuthentification({ visible, children }) {
  const [tanError, setTanError] = useState(false)
  const [transactionId, setTransactionId] = useState()
  const { t } = useTranslation()
  const formRef = useRef()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!transactionId) {
      const cid = qs(document.location.search, 'cid')
      setTransactionId(cid)
      requestTan(cid)
    }
  }, [transactionId])

  React.useEffect(() => {
    if (visible) {
      Analytics.track(new AnalyticsEvent.ContinueLaterTanReloaded())
    }
  }, [visible])

  React.useEffect(() => {
    if (tanError) {
      Analytics.track(new AnalyticsEvent.ContinueLaterTanSubmitError())
    }
  }, [tanError])

  return (
    <React.Fragment>
      {transactionId && (
        <Modal
          className="c-modal--stretch"
          testId="tanAuthentificationModal"
          id="tanAuthentificationModal"
          shown={visible}
          center
          title={t('tanAuthentification:heading')}
          buttonConfirmText={t('tanAuthentification:buttonText')}
          onConfirm={async () => {
            await formRef.current.submitForm()
          }}
          hideCloseButton
        >
          <Paragraph>{t('tanAuthentification:content:p1')}</Paragraph>
          <Paragraph>{t('tanAuthentification:content:p2')}</Paragraph>
          <Paragraph>{t('tanAuthentification:content:p3')}</Paragraph>
          {tanError && (
            <ErrorMessage>
              {t('tanAuthentification:inlineErrorMessage')}
            </ErrorMessage>
          )}
          <Layout center className="u-mb-small">
            <Layout.Item default="1/2" s="1/1">
              <Formik
                initialValues={{ tanAuthentification: '' }}
                validationSchema={TanAuthentificationValidationSchema}
                onSubmit={values =>
                  onValidate(
                    values.tanAuthentification,
                    setTanError,
                    transactionId,
                    navigate
                  )
                }
              >
                {formikProps => {
                  formRef.current = formikProps
                  return (
                    <Form floatingLabel onSubmit={formikProps.handleSubmit}>
                      <Fieldset>
                        <Fieldset.Row>
                          <BrFormField
                            name="tanAuthentification"
                            render={fieldProps => <Input {...fieldProps} />}
                          />
                        </Fieldset.Row>
                      </Fieldset>
                    </Form>
                  )
                }}
              </Formik>
            </Layout.Item>
          </Layout>
          <Button
            element="a"
            link
            icon="semantic-forward"
            onClick={() => {
              Analytics.track(new AnalyticsEvent.ContinueLaterTanRequest())
              requestTan(transactionId, navigate)
            }}
          >
            {t('tanAuthentification:requestTanLink')}
          </Button>
        </Modal>
      )}
      {!visible && children}
    </React.Fragment>
  )
}

const onValidate = (tan, setTanError, transactionId, navigate) => {
  continueJourney(tan, transactionId, navigate)
    .then(
      response => {
        if (!response?.transaction?.status) {
          setTanError(true)
        }
      },
      error => {
        if (error.message.includes('Expired')) {
          navigate('/link-expired')
        } else {
          navigate('/error')
        }
      }
    )
    .catch(() => {
      navigate('/error')
    })
}

const requestTan = (transactionId, navigate) => {
  generateTan(transactionId)
    .then(
      () => null, // SMS sent
      error => {
        if (error.message.includes('Expired')) {
          navigate('/link-expired')
        } else {
          navigate('/error')
        }
      }
    )
    .catch(() => navigate('/error'))
}
