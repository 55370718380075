import moment from 'moment'
import { axios, setAxiosIdToken, setAxiosToken } from '..'
import { Analytics } from '../../analytics'
import { getServiceBrandName } from '../../brand'
import { mapBusModelToFrontendModel } from '../../data/mapping'
import { store } from '../../redux'
import * as dataStorageActions from '../../redux/features/dataStorage'
import * as actions from '../../redux/features/form'
import { qs } from '../../routing'
import { transactionStatus } from '../transaction/transaction'

const {
  REACT_APP_COUNTRYCODE,
  REACT_APP_PRODUCT,
  REACT_APP_ENTRYPOINT,
} = process.env

export function continueJourney(tan, transactionId, navigate) {
  const url = `/continue/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${getServiceBrandName()}/${REACT_APP_ENTRYPOINT}/${transactionId}`
  return axios
    .post(
      url,
      { tan },
      {
        validateStatus: statusCode =>
          [200, 401, 410].indexOf(statusCode) !== -1,
      }
    )
    .then(
      response => {
        if (response.status === 401) {
          return Promise.resolve('Invalid TAN')
        }
        if (response.status === 410) {
          return Promise.reject(new Error('Expired link'))
        }
        if (
          response.status === 400 &&
          response.data?.message === 'Expired link!'
        ) {
          return Promise.reject(response.data)
        }
        if (!response.data?.token || !response.data?.data) {
          return Promise.reject(new Error('MISSING_DATA'))
        }

        const { data, token } = response.data

        loadDataStorageIntoRedux(data, transactionId)
        setAxiosToken(token)
        setAxiosIdToken(transactionId)
        Analytics.updateDefault({
          product: [
            {
              attributes: {
                typeOfSale:
                  data.transaction.salesChannel.salesChannelType === 'online'
                    ? 'Online sales'
                    : 'Hybrid sales',
                recurringPayment: data.financialProduct.pricing?.monthlyPrice,
                duration: data.financialProduct.calculation?.duration,
                yearlyMileage: data.financialProduct.calculation?.yearlyMileage,
                startDateOfContract: moment().format('YYYY-MM-DD'),
              },
              vehicleModel: [
                {
                  manufacturer: data.vehicleData.model?.manufacturer,
                  name: data.vehicleData.model?.name,
                  initialRegistrationDate:
                    data.vehicleData.initialRegistrationDate,
                  engine: {
                    fuelType: data.vehicleData.model?.engine?.fuelType,
                  },
                },
              ],
            },
          ],
        })

        switch (data.transaction?.status) {
          case transactionStatus.INITIALIZED:
          case transactionStatus.LEAD_SUBMITTED:
            navigate('/form')
            return Promise.resolve(data)
          case transactionStatus.APPLICATIONFORM_STARTED:
            navigate('/form/2')
            return Promise.resolve(data)
          case transactionStatus.APPLICATIONFORM_COMPLETED:
            navigate('/form/3')
            return Promise.resolve(data)
          case transactionStatus.ESIGN_READY:
            navigate(`/esign-ready/?url=${qs(window.location.search, 'surl')}`)
            return Promise.resolve(data)
          case transactionStatus.CONTRACT_CONFIRMED:
            navigate('/esign-successful')
            return Promise.resolve(data)
          case transactionStatus.ESIGN_REFUSED:
            navigate('/esign-refused')
            return Promise.resolve(data)
          default:
            return Promise.reject(new Error('INVALID_TRANSACTION_STATUS'))
        }
      },
      error => {
        return Promise.reject(error)
      }
    )
    .catch(error => {
      return Promise.reject(error)
    })
}

const loadDataStorageIntoRedux = (data, transactionId) => {
  if (
    [
      transactionStatus.INITIALIZED,
      transactionStatus.LEAD_SUBMITTED,
      transactionStatus.APPLICATIONFORM_STARTED,
      transactionStatus.APPLICATIONFORM_COMPLETED,
      transactionStatus.ESIGN_READY,
      transactionStatus.ESIGN_REFUSED,
      transactionStatus.CONTRACT_CONFIRMED,
    ].includes(data.transaction?.status)
  ) {
    store.dispatch(dataStorageActions.done(data))

    store.dispatch(
      actions.setFormData({
        ...mapBusModelToFrontendModel(data),
        transactionId,
      })
    )
  }
}
