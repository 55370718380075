import React, { useState } from 'react'
import { Formik } from 'formik'
import {
  Card,
  Checkbox,
  Button,
  Layout,
  Paragraph,
  FormField,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { trustedShopsQuestionnaireLink } from '../../services/api/trustedShops'
import { CustomerHappinessIndexValidationSchema } from '../../services/validation'
import SpinnerElement from '../SpinnerElement'

export default function CustomerHappinessIndex({ transactionId }) {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Formik
      initialValues={{ consent: false }}
      validationSchema={CustomerHappinessIndexValidationSchema}
      onSubmit={async () => {
        setIsSubmitting(true)
        const link = await trustedShopsQuestionnaireLink(
          transactionId
        ).finally(() => setIsSubmitting(false))
        window.open(link, '_blank').focus()
      }}
    >
      {formikProps => (
        <>
          <SpinnerElement visible={isSubmitting} />
          <Layout center>
            <Layout.Item default="1/1">
              <Card title={t('customerHappinessIndex:title')} footer>
                <Paragraph>{t('customerHappinessIndex:text')}</Paragraph>
                <Layout.Item default="1/1">
                  <FormField name="consent" type="checkbox">
                    <Layout center>
                      <Checkbox
                        checked={formikProps.values.consent}
                        onChange={e =>
                          formikProps.setFieldValue('consent', e.target.checked)
                        }
                      >
                        {t('customerHappinessIndex:consent')}
                      </Checkbox>
                      <Button onClick={formikProps.handleSubmit}>
                        {t('customerHappinessIndex:button')}
                      </Button>
                    </Layout>
                  </FormField>
                </Layout.Item>
              </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
              <Paragraph>
                {t('customerHappinessIndex:legalNotice:paragraph1')}
              </Paragraph>
            </Layout.Item>
            <Layout.Item default="1/1">
              <Paragraph>
                {t('customerHappinessIndex:legalNotice:paragraph2:text')}
                <a
                  href={t('customerHappinessIndex:legalNotice:paragraph2:link')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('customerHappinessIndex:legalNotice:paragraph2:link')}
                </a>
                .
              </Paragraph>
            </Layout.Item>
            <Layout.Item default="1/1">
              <Paragraph>
                {t('customerHappinessIndex:legalNotice:paragraph3:text')}
                <a
                  href={t('customerHappinessIndex:legalNotice:paragraph3:link')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('customerHappinessIndex:legalNotice:paragraph3:link')}
                </a>
                .
              </Paragraph>
            </Layout.Item>
            <Layout.Item default="1/1">
              <Paragraph>
                {t('customerHappinessIndex:legalNotice:paragraph4:text')}
                <a
                  href={`${process.env.PUBLIC_URL}${t(
                    'customerHappinessIndex:legalNotice:paragraph4:link'
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {t('customerHappinessIndex:legalNotice:paragraph4:linkText')}
                </a>
                .
              </Paragraph>
            </Layout.Item>
          </Layout>
        </>
      )}
    </Formik>
  )
}
