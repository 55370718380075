import React from 'react'
import { Wizard } from '@vwfs-bronson/bronson-react'
import formConfiguration from './index.json'

const JourneyWizard = ({ step }) => {
  return (
    <Wizard
      collapsedText={formConfiguration.steps[step - 1].name}
      collapseBreakpoint="s"
      noInteraction
    >
      {formConfiguration.steps.map((item, index) => {
        return (
          <Wizard.Item
            key={item.step}
            done={index + 1 < step}
            active={index + 1 === step}
          >
            {item.name}
          </Wizard.Item>
        )
      })}
    </Wizard>
  )
}

export default JourneyWizard
