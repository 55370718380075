import React from 'react'
import {
  ContentSection,
  Heading,
  Paragraph,
  Button,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { Analytics, AnalyticsEvent } from '../../services/analytics'

const JourneyCompleted = () => {
  const { t } = useTranslation()

  React.useEffect(() => {
    Analytics.track(new AnalyticsEvent.OfferAlreadyPurchased())
  }, [])

  return (
    <ContentSection pageWrapSize="small">
      <ContentSection.ComponentWrapper>
        <Heading level="2">{t('journeyCompleted:alreadyPurchased')}</Heading>
        <Paragraph>{t('journeyCompleted:completionMessage')}</Paragraph>
        <Paragraph>{t('journeyCompleted:newPurchase')}</Paragraph>
        <Paragraph>
          <Button
            element="a"
            link
            icon="semantic-forward"
            href={t('journeyCompleted:hyperlink')}
          >
            {t('journeyCompleted:hyperlink')}
          </Button>
        </Paragraph>
        <Heading level="4">{t('journeyCompleted:farewellMessage')}</Heading>
      </ContentSection.ComponentWrapper>
    </ContentSection>
  )
}

export default JourneyCompleted
