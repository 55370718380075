import React from 'react'
import {
  FormField as BronsonReactFormField,
  ErrorMessage,
  InfoIcon,
  FormField,
  Input,
} from '@vwfs-bronson/bronson-react'
import { Field } from 'formik'
import get from 'lodash/get'

export function FormFieldInput({
  name,
  labelText,
  labelForId,
  formProps,
  disabled,
  onChange,
  addonText,
  noBackground,
  readOnly,
  defaultValue,
}) {
  return (
    <FormField
      name={name}
      type="input"
      labelText={labelText}
      labelForId={labelForId ?? name}
      noFloatingLabel
      errorMessage={
        get(formProps?.errors, name) && get(formProps?.touched, name) ? (
          <ErrorMessage id={name}>
            {get(formProps?.touched, name) && get(formProps?.errors, name)}
          </ErrorMessage>
        ) : (
          ''
        )
      }
    >
      <Input
        name={name}
        disabled={disabled}
        onChange={
          onChange ?? (e => formProps.setFieldValue(name, e.target.value))
        }
        addonText={addonText}
        noBackground={noBackground}
        readOnly={readOnly}
        defaultValue={defaultValue ?? formProps.values[name] ?? ''}
      />
    </FormField>
  )
}

export function BrFormField({
  render,
  name,
  labelElement = 'label',
  labelText,
  hint,
  infoIcon,
  notion,
  type = 'input',
  className,
  testId,
  noFloatingLabel = false,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <BronsonReactFormField
          testId={testId}
          className={className}
          labelElement={labelElement || 'label'}
          labelText={
            <>
              {labelText}
              {notion && '*'}
              {infoIcon && <InfoIcon icon="semantic-info">{infoIcon}</InfoIcon>}
            </>
          }
          type={type}
          errorMessage={
            get(form.errors, name) && get(form.touched, name) ? (
              <ErrorMessage id={name}>
                {get(form.touched, name) && get(form.errors, name)}
              </ErrorMessage>
            ) : (
              ''
            )
          }
          hint={hint}
          noFloatingLabel={noFloatingLabel}
        >
          {render({
            name,
            error: !!(get(form.touched, name) && get(form.errors, name)),
            ...field,
          })}
        </BronsonReactFormField>
      )}
    </Field>
  )
}
