export const SET_KEYCLOAK_DATA = 'keycloak//SET_KEYCLOAK_DATA'
export const SET_KEYCLOAK_IDP = 'keycloak//SET_KEYCLOAK_IDP'
export const SET_KEYCLOAK_IDP_HINT = 'keycloak//SET_KEYCLOAK_IDP_HINT'

export function setKeycloakData(data) {
  return {
    type: SET_KEYCLOAK_DATA,
    payload: data,
  }
}

export function setKeycloakIdp(idp) {
  return {
    type: SET_KEYCLOAK_IDP,
    payload: idp,
  }
}

export function setKeycloakIdpHint(idpHint) {
  return {
    type: SET_KEYCLOAK_IDP_HINT,
    payload: idpHint,
  }
}

export function getKeycloakData(store) {
  return store.keycloak?.data
}

export function getKeycloakIdp(store) {
  return store.keycloak?.idp
}

export function getKeycloakIdpHint(store) {
  return store.keycloak?.idpHint
}

const initialState = {
  data: {},
  idp: null,
  idpHint: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_KEYCLOAK_DATA:
      return {
        ...state,
        ...action.payload,
      }

    case SET_KEYCLOAK_IDP:
      return {
        ...state,
        ...action.payload,
      }

    case SET_KEYCLOAK_IDP_HINT:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
