import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom/client'

import { BrowserRouter, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'

import './services/i18n'
import { store } from './services/redux'

import KeycloakWrapper from './KeycloakWrapper'

const PositionRestore = () => {
  const location = useLocation()
  const [prevLocation, setPrevLocation] = React.useState(location.pathname)
  React.useEffect(() => {
    if (prevLocation !== location.pathname) {
      setPrevLocation(location.pathname)

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [location, prevLocation])

  return null
}

function Init() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PositionRestore />
        <React.Fragment>
          <KeycloakWrapper />
        </React.Fragment>
      </BrowserRouter>
    </Provider>
  )
}

if (module.hot) {
  module.hot.accept(render)
}

function render() {
  const rootElement = document.getElementById('root')
  ReactDOM.createRoot(rootElement).render(<Init />)
}

render()
